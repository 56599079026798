
import zh_CN from './zh_CN';
// import en_US from './en_US';

export default {
  'zh_CN': {
    question: zh_CN
  },
  // 'en_US': {
  //   question: en_US
  // },
}
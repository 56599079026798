import questionControl from './asqQuestionControl';
export default {
  mixins: [questionControl],
  props: {
    // 值
    value: {
      type: [Array, Object],
      default: null
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [
          // { text: '选项1', value: '1' },
          // { text: '选项2', value: '2' },
          // { text: '选项3', value: '3' },
          // { text: '选项4', value: '4' },
        ];
      },
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showAllLevels: {
      type: Boolean,
      default: true
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("question-item-meta-data");
      }
    },
    controlMode: {
      type: String,
      default: "cascader"
    }
  },
  watch: {
    value: {
      handler(to) {
        if (!to || !to.length) return;
        this.initValue(to);
      },
      immediate: true,
    },
    items() {
      this.initValue(this.value);
    },
  },
  methods: {
    getDefaultOptions() {
      return [{
        text: "选项1",
        value: "1",
      },
      {
        text: "选项2",
        value: "2",
      },
      {
        text: "选项3",
        value: "3",
      },
      {
        text: "选项4",
        value: "5"
      },
      ];
    },
    initValue(v) {
      if (this.isDesign) return;
      if (this.multiple) {
        if (dsf.isArray(v)) {
          if (!v.length) return;
        } else if (dsf.isObject(v)) {
          v = [v];
        } else {
          this.emitValueChange([]);
          return;
        }
      } else {
        if (!v) return;
        if (dsf.isArray(v)) {
          if (!v.length) {
            this.emitValueChange(null);
            return;
          }
        } else if (dsf.isObject(v)) {
          v = [v];
        } else if (v) {
          this.emitValueChange(null);
          return;
        }
      }
      let isUpdate = false, res = [];
      for (let i = 0, l = v.length; i < l; i++) {
        let oItem = v[i];
        let nItem = this.getFullValuePath(oItem.value);
        res.push(nItem);
        if (oItem.text != nItem.text) {
          isUpdate = true;
        }
      }
      if (isUpdate) {
        this.updateValue(res);
      }
    },
    getValuePath(val) {
      let res = [];
      dsf.deepForEach(this.items, (item, level) => {
        let value = item.value;
        res.length = level + 1;
        res[level] = value;
        if (val === value) return -1;
      });
      return res;
    },
    getFullValuePath(val) {
      let texts = [];
      dsf.deepForEach(this.items, ({ value, text }, level) => {
        texts.length = level + 1;
        texts[level] = text;
        if (val === value) return -1;
      });
      return {
        value: val,
        text: texts.join(' / ')
      };
    },
    updateValue(v) {
      if (!this.multiple) {
        v = v[0];
      }
      this.emitValueChange(v);
      this.$nextTick(() => {
        this.$gotoLogicHandler();
      });
    }
  }
}
/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 14:45:47
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-28 14:15:44
 */
export default function (dsf, options) {
  return {
    id: "",
    name: "",
    fill_desc:"",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    asqType: "table",
    group: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    dataSourceWatch: true,
    defaultValue: null,
    dataSource: {
      code: null,
      type: "static",
      attach: [],
    },
    
    columnsMap:[],
    columnsSetting:{},
    valueAttributes: [
      {
        "name": "大文本",
        "code": "text",
        "type": dsf.metadata.getDataType("object"),
        "length": "",
        "defaultValue": null,
        "unit": null,
        "encrypt":false
      }
    ]
  };
}
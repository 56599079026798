export default function (dsf, options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    asqType: "score",
    group: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    dataSourceWatch: true,
    defaultValue: null,
    dataSource: {
      code: null,
      type: "static",
      attach: [],
    },
    valueAttributes: [{
      name: "值",
      code: "value",
      type: dsf.metadata.getDataType("string"),
      length: 100,
      defaultValue: null,
      emptyValidate: true,
      encrypt: false,
      unit: null
    },
    {
      name: "文本",
      code: "text",
      type: dsf.metadata.getDataType("string"),
      length: 100,
      defaultValue: null,
      encrypt: false,
      unit: null
    }, {
      name: "分数",
      code: "score",
      type: dsf.metadata.getDataType("number"),
      length: 100,
      defaultValue: null,
      encrypt: false,
      unit: null
    }]
  };
}
/*
 * @Descripttion: 默认选项
 * @Author: zhanghang
 * @Date: 2021-12-16 10:44:16
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-28 14:24:33
 */
export default {
  created() {
    if (this.isDesign && !this.metadata.dataSource.code) {
      console.log('this.ctrlType', this.ctrlType)
      switch (this.ctrlType) {
        //矩阵填空
        case 'dsf.asqquestionmatrixinput':
          let matrixInput = [[
            {
              text: '填空1',
              uuid: dsf.uuid(32),
              setting: {
                inputsize: 'medium',
                reg: null,
                max: '',
                min: '',
                required: false,
                type: 'input'
              }
            },
            {
              text: '填空2',
              uuid: dsf.uuid(32),
              setting: {
                inputsize: 'medium',
                reg: null,
                max: '',
                min: '',
                required: false,
                type: 'input'
              }
            }, {
              text: '填空3',
              uuid: dsf.uuid(32),
              setting: {
                inputsize: 'medium',
                reg: null,
                max: '',
                min: '',
                required: false,
                type: 'input'
              }
            }
          ],
          [{
            text: '矩阵1',
            uuid: dsf.uuid(32),
            setting: {
              inputsize: 'medium',
              reg: null,
              max: '',
              min: '',
              required: false,
              type: 'input'
            }
          },
          {
            text: '矩阵2',
            uuid: dsf.uuid(32),
            setting: {
              inputsize: 'medium',
              reg: null,
              max: '',
              min: '',
              required: false,
              type: 'input'
            }
          }, {
            text: '矩阵3',
            uuid: dsf.uuid(32),
            setting: {
              inputsize: 'medium',
              reg: null,
              max: '',
              min: '',
              required: false,
              type: 'input'
            }
          }]
          ]
          this.metadata.dataSource.code = matrixInput

          // 列-text-id
          this.metadata.columnsMap = matrixInput[0].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          // 行-text-id
          this.metadata.rowsMap = matrixInput[1].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          break;
        //矩阵打分
        case 'dsf.asqquestionmatrixscore':
          let matrixScore = [
            [
              {
                text: '打分1',
                uuid: dsf.uuid(32),
              },
              {
                text: '打分2',
                uuid: dsf.uuid(32),
              }, {
                text: '打分3',
                uuid: dsf.uuid(32),
              }
            ],
            [{
              text: '矩阵1',
              uuid: dsf.uuid(32),
            },
            {
              text: '矩阵2',
              uuid: dsf.uuid(32),
            }, {
              text: '矩阵3',
              uuid: dsf.uuid(32),
            }]
          ]
          this.metadata.dataSource.code = matrixScore
          // 列-text-id
          this.metadata.columnsMap = matrixScore[0].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          // 行-text-id
          this.metadata.rowsMap = matrixScore[1].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          break;
        //矩阵单选
        case 'dsf.asqquestionmatrixselect' || 'dsf.asqquestionmatrixmutilselect':
          let matrixSelect = [
            [
              {
                text: '选项1',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              },
              {
                text: '选项2',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              }, {
                text: '选项3',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              }
            ],
            [{
              text: '矩阵1',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            },
            {
              text: '矩阵2',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            }, {
              text: '矩阵3',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            }]
          ]
          this.metadata.dataSource.code = matrixSelect
          // 列-text-id
          this.metadata.columnsMap = matrixSelect[0].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          // 行-text-id
          this.metadata.rowsMap = matrixSelect[1].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          break;
        //矩阵多选
        case 'dsf.asqquestionmatrixmutilselect':
          let matrixMutilSelect = [
            [
              {
                text: '选项1',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              },
              {
                text: '选项2',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              }, {
                text: '选项3',
                uuid: dsf.uuid(32),
                setting: {
                  isinput: false,
                  reg: null,
                  limit: null,
                  required: false,
                  type: 'select'
                }
              }
            ],
            [{
              text: '矩阵1',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            },
            {
              text: '矩阵2',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            }, {
              text: '矩阵3',
              uuid: dsf.uuid(32),
              setting: {
                isinput: false,
                reg: null,
                limit: null,
                required: false,
                type: 'select'
              }
            }]
          ]
          this.metadata.dataSource.code = matrixMutilSelect
          // 列-text-id
          this.metadata.columnsMap = matrixMutilSelect[0].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          // 行-text-id
          this.metadata.rowsMap = matrixMutilSelect[1].map((it) => {
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          break;
        //自增表格
        case 'dsf.asqquestiontable':
          let uuid =  dsf.uuid(32)
          let table = [{
            text: "姓名",
            uuid: dsf.uuid(32),
            type: '1',
            required: true
          },
          {
            text: "年龄",
            uuid: dsf.uuid(32),
            type: '3',
            required: true
          },
          {
            text: "性别",
            uuid,
            type: '2',
            seting: [{ text: '男', value: '1' }, { text: '女', value: '2' }],
            required: true
          },
          ]
          this.metadata.dataSource.code = table

          let columnsSetting = {}
          // 列-text-id
          this.metadata.columnsMap = table.map((it) => {
            if (it.seting) {
              columnsSetting[it.uuid] = it.seting
            }
            return {
              text: it.text,
              uuid: it.uuid
            }
          })
          this.metadata.columnsSetting = columnsSetting
          break;
        // 判断题
        case 'dsf.asqquestionjudge':
          this.metadata.dataSource.code = [{
            text: "正确",
            value: "1",
          },
          {
            text: "错误",
            value: "0",
          },
          ]
          break;

        default:
          this.metadata.dataSource.code = this.getDefaultOptions();
          break;
      }
    }
  },
  methods: {
    // 设置默认选项
    getDefaultOptions() {
      return [{
        text: "选项1",
        value: "1",
        attach:'',
      },
      {
        text: "选项2",
        value: "2",
        attach:'',
      },
      ];
    },
  }
}
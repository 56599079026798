/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-15 10:50:17
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-03-08 14:46:56
 */

import questionItemMetaData from "./questionItemMetaData";
import questionOrderItemMetaData from "./questionOrderItemMetaData";
import questionScoreItemMetaData from "./questionScoreItemMetaData";
import questionProportionItemData from "./questionProportionItemData";
import questionSignatureItemMetaData from "./questionSignatureItemMetaData";
import questionFillMetaData from "./questionFillMetaData";


import questionTableMetaData from "./questionTableMetaData";
import questionMatrixMetaData from "./questionMatrixMetaData";

dsf.metadata.add("question-item-meta-data",questionItemMetaData);
dsf.metadata.add("question-order-item-meta-data",questionOrderItemMetaData);
dsf.metadata.add("question-score-item-meta-data",questionScoreItemMetaData);
dsf.metadata.add("question-proporty-item-meta-data",questionProportionItemData);
dsf.metadata.add("question-signature-item-meta-data",questionSignatureItemMetaData);
dsf.metadata.add("question-fill-meta-data",questionFillMetaData);

dsf.metadata.add("question-table-item-meta-data",questionTableMetaData);
dsf.metadata.add("question-matrix-item-meta-data",questionMatrixMetaData);


/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 14:45:47
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-24 17:01:34
 */
export default function (dsf, options) {
  return {
    id: "",
    name: "",
    fill_desc:"",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    asqType: "text",
    group: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    dataSourceWatch: true,
    defaultValue: null,
    dataSource: {
      code: null,
      type: "static",
      attach: [],
    },
    valueAttributes: [{
      name: "文本",
      code: "fill",
      type: dsf.metadata.getDataType("string"),
      length: 100,
      defaultValue: null,
      emptyValidate: true,
      encrypt: false,
      unit: null
    }]
  };
}
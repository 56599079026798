import questionControl from './asqQuestionControl';
import questionDefaultOptions from './asqQuestionDefaultOptions';
export default {
  mixins: [questionControl, questionDefaultOptions],
  props: {
    // 值
    value: {
      type: [Array, Object],
      default: null
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [

        ];
      },
    },
    //是否有其他选项
    hasOther: {
      type: Boolean,
      default: false
    },
    optionsLayout: {
      type: String,
      default: "2"
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("question-item-meta-data");
      }
    },
    // 多选
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    controlMode: {
      type: String,
      default: "enum"
    }
  },
  data() {
    return {
      valueCopy: []
    };
  },
  computed: {
    $items() {
      if (this.hasOther) {
        return [...this.items, { text: '其他', value: '99', attach: '' }]
      } else {
        return [...this.items]
      }

    },
    getLastOption: {
      get() {
        return _.last(this.$items);
      },
      set(to) {
        let nv;
        if (to === void 0 || to === null) {
          nv = [];
        } else if (dsf.type(to) === "array") {
          nv = this.filterValue(to);
        } else {
          nv = this.filterValue([to]);
        }
        this.valueCopy = nv;
        this.updateValue(nv)
      }
    },
    selectValue: {
      get() {
        if (this.multiple) {
          if (dsf.isArray(this.valueCopy)) {
            return this.valueCopy.map((v) => v.value);
          }
          return [];
        } else {
          if (dsf.isArray(this.valueCopy) && this.valueCopy.length) {
            return this.valueCopy[0].value;
          }
          return "";
        }
      },
      set(v) {
        this.isDesign || this.initValue(v);
      },
    },
  },
  watch: {
    value: {
      handler(to) {
        this.initValue(to);
      },
      immediate: true,
    },
    $items() {
      this.initValue(this.value);
    },
    max(v, ov) {
      if (!this.metadata.validate) {
        this.metadata.validate = {
          code: null,
          rules: []
        };
      }
      let rule = _.find(this.metadata.validate.rules, (it) => {
        return it.type == "array" && it.attr == "value";
      });
      if (!rule) {
        rule = dsf.validate.fn.getMetaDataValidateObject();
        rule.attr = "value";
        rule.type = "array";
        rule.arrayMax = v;
        rule.errorMsg = "该项只能选择@[arrayMin]-@[arrayMax]个选项"
        this.metadata.validate.rules.push(rule);
      } else {
        rule.attr = "value";
        rule.type = "array";
        rule.arrayMax = v;
      }
    },
    min(v, ov) {
      if (!this.metadata.validate) {
        this.metadata.validate = {
          code: null,
          rules: []
        };
      }
      let rule = _.find(this.metadata.validate.rules, (it) => {
        return it.type == "array" && it.attr == "value";
      });
      if (!rule) {
        rule = dsf.validate.fn.getMetaDataValidateObject();
        rule.attr = "value";
        rule.type = "array";
        rule.arrayMin = v;
        rule.errorMsg = "该项只能选择@[arrayMin]-@[arrayMax]个选项"
        this.metadata.validate.rules.push(rule);
      } else {
        rule.attr = "value";
        rule.type = "array";
        rule.arrayMin = v;
      }
    }
  },
  methods: {
    initValue(to) {
      let nv;
      if (to === void 0 || to === null) {
        nv = [];
      } else if (dsf.type(to) === "array") {
        nv = this.filterValue(to);
      } else {
        nv = this.filterValue([to]);
      }

      if (!this.isSame(nv, this.valueCopy)) {
        this.valueCopy = nv;
        this.updateValue(nv);
      }
    },
    filterValue(value) {
      let newValue = [];
      _.forEach(value, v => {
        let _v = void 0;
        if (dsf.isObject(v) && v.value !== void 0) {
          _v = this.getObjectByValue(v.value, v.attach);
        } else if (dsf.isString(v) || dsf.isNumber(v)) {
          _v = this.getObjectByValue(v);
        }
        if (_v) {
          newValue.push(_v);
        }
      });
      return newValue;
    },
    getObjectByValue(v, attach = '') {
      let res = null;
      dsf.deepForEach(this.$items, (item) => {
        let str1 = dsf.isDef(item.value) ? item.value.toString() : ""
        let str2 = dsf.isDef(v) ? v.toString() : ""
        if (str1 === str2) {
          item.attach = attach
          res = item;
          return -1;
        }
      });
      return res;
    },
    // 判断两个值是否相同
    isSame(p1, p2) {
      if (dsf.type(p1) === "array" && dsf.type(p2) === "array") {
        if (p1.length !== p2.length) return false;
        for (let i = 0; i < p1.length; i++) {
          let same = false;
          for (let j = 0; j < p2.length; j++) {
            let c1 = p1[i].text === p2[j].text,
              c2 = p1[i].value === p2[j].value,
              c3 = p1[i].attach === p2[j].attach;
            if (c1 && c2 && c3) {
              same = true;
              break;
            }
          }
          if (!same) {
            return false;
          }
        }
        return true;
      }
      return p1 === p2;
    },
    updateValue(val) {
      val = _.map(val, v => {
        return {
          text: v.text,
          value: v.value,
          attach: v.attach || ""
        }
      });
      if (!this.multiple) {
        val = val.length ? val[0] : null;
      }
      // console.log('提交', val)
      this.emitValueChange(val);
      this.$nextTick(() => {
        this.$gotoLogicHandler();
      });
    },
  }
}

import questionControl from './asqQuestionControl';
export default {
  mixins: [questionControl],
  props: {
    defaultScore:{
      type:Number,
      default:0,
    },
    // 值
    value: {
      type: [Array, Object],
      default: null
    },
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get("question-score-item-meta-data");
        return md;
      }
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 5
    },
    // 选项
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    showStyle: {
      type: [String, Object],
      default() {
        return "stars";
      }
    },
    viewLeft: {
      type: String,
      default: ""
    },
    viewCenter: {
      type: String,
      default: ""
    },
    viewRight: {
      type: String,
      default: ""
    },
    controlMode: {
      type: String,
      default: "score"
    }
  },
  data() {
    return {
      valueCopy: [],
      // 颜色
      colors: [this.$root.currentTheme.normal, this.$root.currentTheme.normal, this.$root.currentTheme.normal],
      iconClasses: {
        heart: ["iconfont icon-icon_love_active", "iconfont icon-icon_love_active", "iconfont icon-icon_love_active"],
        stars: ["iconfont icon-icon_star_fil1", "iconfont icon-icon_star_fil1", "iconfont icon-icon_star_fil1"],
        face: ["iconfont icon-icon_emoji_fine", "iconfont icon-icon_emoji_fine", "iconfont icon-icon_emoji_fine"],
        praise: ["iconfont icon-icon_zan_good2", "iconfont icon-icon_zan_good2", "iconfont icon-icon_zan_good2"]
      },
      voidClasses: {
        heart: "iconfont icon-icon_love_normal",
        stars: "iconfont icon-icon_star_line1",
        face: "iconfont icon-icon_emoji_fine",
        praise: "iconfont icon-icon_zan_good"
      },
      voidClass: "iconfont icon-icon_star_line1",
      curIcon: [],
    };
  },
  computed: {},
  watch: {
    //选项变化重新设置valueCopy
    items: {
      handler() {
        this.initValueCopy();
      },
      immediate: true
    },
    valueCopy: {
      handler(v, ov) {
        if (dsf.isDef(v)) {
          let val = [];
          v?.forEach(item => {
            let newItem = { ...item };
            val.push(newItem);
          });
          if (!this.isSame(this.value, this.valueCopy)) {
            this.emitValueChange(val);
          }
        }
      },
      deep: true,
    },
    value: {
      handler(v, ov) {
        if (this.design) {
          return;
        }
        if (v?.length && !this.isSame(v, this.valueCopy)) {
          this.valueCopy = v;
        }
      },
      immediate: true
    },
    showStyle: {
      handler(v, ov) {
        if (v !== "slider") {
          this.curIcon = this.iconClasses[v];
          this.voidClass = this.voidClasses[v];
          return;
        }
      },
      immediate: true
    }
  },
  methods: {
    changeScore(score, index) {
      if (score.detail) {
        score = score.detail
      }
      let item = this.valueCopy[index];
      item.score = score + this.min - 1
      this.valueCopy[index] = item
    },
    initValueCopy() {
      this.items.forEach((it) => {
        it.score = this.defaultScore
      });
      this.valueCopy = this.items;
    },
    formatTooltip(val) {
      return val;
    },
    // 判断两个值是否相同
    isSame(p1, p2) {
      if (dsf.type(p1) === "array" && dsf.type(p2) === "array") {
        if (p1.length !== p2.length) return false;
        for (let i = 0; i < p1.length; i++) {
          let same = false;
          for (let j = 0; j < p2.length; j++) {
            let c1 = p1[i].text === p2[j].text,
              c2 = p1[i].value === p2[j].value,
              c3 = p1[i].score === p2[j].score;
            if (c1 && c2 && c3) {
              same = true;
              break;
            }
          }
          if (!same) {
            return false;
          }
        }
        return true;
      }
      return p1 === p2;
    },
  },
}

const itemClassName = 'ds-question-item';
let serial = 0;
export default {
  // inject: {
  //   $qiGroup: {
  //     default: null
  //   },
  // },
  props: {
    isQuestionControl: {
      type: Boolean,
      default: true
    },
    questionIndex: {
      type: [Number, String, Function],
      default: 0
    },
    stem: {
      type: String,
      default: "请输入题干"
    },
    //跳题,显示逻辑
    logicConfig: {
      type: Object,
      default() {
        return {
          goto: {
            list: []
          },
          show: {
            andOr: "and",
            list: []
          }
        }
      }
    },
    showIndex: {
      type: Boolean,
      default: true
    },
    //题干说明
    remark: {
      type: String,
      default: ""
    },
    //是否显示题干说明
    showRemark: {
      type: Boolean,
      default: false
    },
    //索引规则
    indexRule: {
      type: String,
      default: "auto"
    },
    //是否被逻辑忽略
    logicIgnore: {
      type: Boolean,
      default: false
    },
    //允许接受分组
    allowGroup: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  mixins: [$mixins.formControl],
  computed: {
    $questionIndex() {
      // 
      return this.questionIndex;
    },
    $isFirst() {
      let arr = [];
      let container = this.isDesign ? this.$designer.$refs.viewProxy : this.$vm.$refs.page;
      dsf.designer.recursionLayoutTree(container, null, null, (it) => {
        if (it.isQuestionControl) {
          arr.push(it);
        }
      });
      let index = _.findIndex(arr, (it) => it.$designId == this.$designId);
      return index == 0;
    }
  },
  created() {
    if (this.isDesign) {
      //监听序号和题干文字的变更
      this.$watch(function () {
        return this.$questionIndex + "|" + this.stem;
      }, function (v, ov) {
        if (dsf.isDef(v) && v != ov) {
          this.label = $(`<div>${this.$questionIndex ? this.$questionIndex + "、" : ""}${this.stem || ''}</div>`).text();
        }
      },{
        immediate:true
      })
    }
  },
  updated() {
    this.$addDefaultClass()
  },

  mounted() {
    this.$addDefaultClass()
  },
  beforeDestroy() {
  },
  methods: {
    $addDefaultClass() {
      if (this?.$el?.nodeType == 1) {
        this.$el.classList.add("ds-question-item");
      }
    },
    $resetQuestionIndex() {
      if (this.isDesign) {
        this?.$designer?.$refreshIndex?.();
      }
    },
    $gotoLogicHandler() {

    },
    $showLogicHandler() {

    }
  },
  watch: {
    "indexRule": {
      handler(v, ov) {
        if (this.isDesign) {
          this.$resetQuestionIndex();
        }
      }
    }
  },
  design: {
    isMask: false,
    showCaption: true,
    infoButton: true,
    settingButton: true,
    layout: {
      //#开头表示该属性在预览模式下会被保留
      exclude: ["value", "#items"]
    },
    // settingButtonHandler() {
    //   this.$openDialog({
    //     title: this.$options.ctrlCaption + "属性编辑",
    //     content: "DesQuestionItemSetting",
    //     params: {
    //       designer: this.$designer,
    //       ctrlType: this.ctrlType,
    //       owner: this
    //     },
    //     width: '50vw',
    //     height: '60vh',
    //     overflow: "hidden auto",
    //     btns: [
    //       {
    //         text: "确定",
    //         handler() {

    //         }
    //       },
    //       {
    //         text: "取消"
    //       }
    //     ]
    //   })
    // },
    settingMenus: null,
  }
};


function designerQuestion(slot) {
  let arr = [];
  function fn(slot) {
    _.each(slot.controls, (it) => {
      arr.push(it);
      if (it.slots) {
        _.each(it.slots, (s) => {
          let arr1 = fn(s);
          arr.push(...arr1);
        })
      }
    })
  }
  fn(slot)
  return arr;
}
/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-28 17:02:27
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-14 18:15:59
 */
//注入平台公共接口调用
export default (dsf) => {
  let addAPI = dsf.api.addAPI;
  //每个函数最后二个参数必须是options和baseUrl，且函数不可以使用箭头函数
  // addAPI("demo", function (ns, params,options, baseUrl) {
  //   let url = "xxx/xxx/xxx"
  //   return this.$http.get(url, params, options,baseUrl);
  // });


  //问卷设计器保存接口
  addAPI("questionBuild", function (params, options, baseURL) {
    let url = ":/asq/asq-info/build"
    return this.$http.post(url, params, options, baseURL);
  });
  //问卷模板保存
  addAPI("questionTemplateBuild", function (params, options, baseURL) {
    let url = ":/asq/asq-temp/build"
    return this.$http.post(url, params, options, baseURL);
  });
  //问卷模板发布
  addAPI("questionBuildPublish", function (params, options, baseURL) {
    let url = ":/asq/asq-info/buildPublish"
    return this.$http.post(url, params, options, baseURL);
  });
  //问卷初始化
  addAPI("questionInit", function (params, options, baseURL) {
    let url = ":/asq/asq-info/paperInit"
    return this.$http.get(url, params, options, baseURL)
  });
  //问卷保存接口
  addAPI("questionSave", function (params, options, baseURL) {
    let qs = dsf.url.queryStringStringify(this.queryString);
    let url = `:/asq/asq-info/persistData${qs ? "?" + qs : ""}`;
    return this.$http.post(url, params, options, baseURL);
  });
  addAPI("getQuestionData", function (params, options, baseURL) {
    let url = ":/asq/asq-info/detail"
    return this.$http.get(url, params, options, baseURL);
  })
  //通过问卷设计器中的nameSpace获取设置表单的id
  addAPI("getQuestionSettingIdForNameSpace", function (params, options, baseURL) {
    let url = ":/asq/asq-info/getAsqInfoId"
    return this.$http.get(url, params, options, baseURL);
  })
}
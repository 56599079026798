export default {
  mixins: [$mixins.form],
  data() {
    return {
      questionWatchs: [],
      _batch_id: this.queryString.batch_id,
    }
  },
  computed: {
    $batch_id: {
      get() {
        return this.$data._batch_id;
      },
      set(v) {
        this.$data._batch_id = v;
      }
    }
  },
  created() {
    this.$$logicHandler = _.debounce(this.$logicHandler, 30);
    // this.$logicHandler()
    this.$watchViewDataChange();
    if (!this.$isMobile) {
      if (!this.$dialog && !this.queryString.isview) {
        // alert('d')
        window.addEventListener('beforeunload', this.windowCloseBefore);
      }
    }

  },
  mounted() {
    // this.$createButtons();
    // window.ddd = this;
  },
  beforeDestroy() {
    _.each(this.questionWatchs, (it) => {
      dsf.isFunction(it) && it();
    })
    if (!this.$isMobile) {
      if (!this.$dialog && !this.queryString.isview) {
        window.removeEventListener('beforeunload', this.windowCloseBefore)
      }
    }
  },
  methods: {
    windowCloseBefore(evt) {
      evt.preventDefault();
      evt.returnValue = "您输入的内容尚未保存，确定离开此页面吗？"
    },
    //问卷值变化后处理
    $watchViewDataChange() {
      let _this = this;
      for (let k in this.metadataDict) {
        let unwatch = this.$watch(function () {
          return _this.$viewData[k];
        },
          function (v, ov) {
            if (!_this.$isReady) {
              this.$logicHandler && _this.$logicHandler()
            }
            else {
              // this.$refs.page.show = true;
              _this.$nextTick(() => {
                this.$$logicHandler && _this.$$logicHandler()
              });
            }

          }, {
          immediate: false,
          deep: true,
        });
        this.questionWatchs.push(unwatch);
      }
    },
    $logicHandler() {
      _.each(this.$postFields, (it) => {
        let prop = this.$controls[it.$designId];
        prop.visible = true;
        prop.logicIgnore = false;
      })
      for (let f = 0; f < this.$postFields.length; f++) {
        let ctrl = this.$postFields[f];
        if (dsf.isUnDef(ctrl)) {
          continue;
        }
        let key = ctrl.$formName;
        let result = this.$itemLogicHandler(ctrl, this.$viewData[key]);
        //跳题处理
        if (result?.goto?.question) {
          let target = this.$refs[result.goto.question] || null;
          if (target) {
            //获取当前题目至跳转目标题目中间的所有题目，进行隐藏
            let startIndex = _.findIndex(this.$postFields, (it) => it == ctrl)
            let endIndex = _.findIndex(this.$postFields, (it) => it == target)
            if (startIndex >= 0 && endIndex >= 0 && endIndex > startIndex) {
              for (let i = startIndex + 1; i < endIndex; i++) {
                let c = this.$postFields[i];
                let prop = this.$controls[c.$designId];
                prop.visible = false;
                prop.logicIgnore = true;
              }
            }
          }
        }
        //逻辑显示或隐藏
        if (dsf.isDef(result.show)) {
          let prop = this.$controls[ctrl.$designId];
          if (prop.visible) {
            prop.visible = result.show;
            prop.logicIgnore = !result.show;
          }
        }
      }
    },
    $itemLogicHandler(item, currentVal) {
      let config = item.logicConfig;
      let value = currentVal;
      let result = {
        goto: null,
        show: null
      }
      //处理跳转
      if (config?.goto?.list?.length > 0) {
        let goto = null;
        for (let i = 0; i < config.goto.list.length; i++) {
          let it = config.goto.list[i];
          let goToResult = this.$gotoLogicHandler(item, it, value);
          if (goToResult) {
            result.goto = it;
            break;
          }
        }
      }
      else if (config?.show?.list?.length > 0) {
        let andOr = config?.show?.andOr || "and";
        let arr = [];
        for (let i = 0; i < config.show.list.length; i++) {
          let it = config.show.list[i];
          let fromCtrl = this.$refs[it.question];
          let showResult = false;
          showResult = this.$showLogicHandler(it);
          arr.push(showResult);
        }
        if (andOr == 'and') {
          let r = _.filter(arr, (v) => v === true);
          result.show = r.length == arr.length;
        }
        else {
          let r = _.filter(arr, (v) => v === true);
          result.show = r.length > 0 ? true : false;
        }
      }
      return result;
    },
    //跳转逻辑
    $gotoLogicHandler(ctrl, goto, value) {
      const mode = ctrl.controlMode;
      if (mode == "enum") {
        let gotoVal = dsf.isArray(goto.value) ? goto.value : [goto.value];

        value =  Array.isArray(value)?value:[value]

        let v = _.map(value, "value");

        let gotoResult = _.filter(gotoVal, (gv) => {
          return v.indexOf(gv) >= 0;
        })
        return gotoResult.length == gotoVal.length
      }
      // 打分
      else if (mode == "score") {
        let scoreResult = value?.filter(item => {
          if (goto.startScore === 'any') {
            return true;
          } else {
            return (item.value == goto.value) && (goto.startScore <= item.score) && (item.score <= goto.endScore);
          }
        });
        return scoreResult?.length > 0;
      }
      // 比重
      else if (mode === "proportion") {
        let res = value?.filter(it => {
          if (goto.symbol === '|') {
            return (it.value === goto.value) && it.score >= goto.minValue && it.score <= goto.maxValue
          } else {
            return (it.value === goto.value) && eval(`${it.score}${goto.symbol}${goto.minValue}`)
          }
        })
        return res?.length > 0;
      }
      // 级联
      else if (mode == "cascader") {
        if (!dsf.isArray(goto.value) || !goto.value.length) return;
        let gotoVal = goto.value;
        if (!dsf.isArray(gotoVal[0])) {
          gotoVal = [gotoVal];
        }
        if (!dsf.isArray(value)) {
          value = [value];
        }
        gotoVal = _.map(gotoVal, g => _.last(g));
        let v = _.map(value, "value");
        let gotoResult = _.filter(gotoVal, (gv) => {
          return v.indexOf(gv) >= 0;
        })
     
        return gotoResult.length == gotoVal.length;
      }
    },
    //显示逻辑
    $showLogicHandler(show) {
      let refCtrl = this.$refs[show.question];
      if (refCtrl) {
        //关联组件
        let refCtrlValue = this.$viewData[refCtrl.$formName] || [];
        let refValue = [];
        if (refCtrlValue) {
          refValue = dsf.isArray(refCtrlValue) ? refCtrlValue : [refCtrlValue];
        }
        //判断逻辑
        let condition = show.condition;
        //符合逻辑的值
        let whereValue = [];
        if (show.value) {
          whereValue = dsf.isArray(show.value) ? show.value : [show.value]
        }
        if (condition) {
          if (refCtrl.controlMode == 'enum') {
            if (condition == 'selected') {
              let refValueArr = _.map(refValue, "value");
              let r = _.filter(refValueArr, (v) => whereValue.indexOf(v) >= 0);
              return r.length == whereValue.length
            }
            else if (condition == 'notSelected') {
              let refValueArr = _.map(refValue, "value");
              let r = _.filter(refValueArr, (v) => whereValue.indexOf(v) >= 0);
              return r.length != whereValue.length
            }
          }
          if (refCtrl.controlMode == 'cascader') {
            let transArr = _.map(whereValue, g => _.last(g))
            if (condition == 'selected') {
              let refValueArr = _.map(refValue, "value");
              let r = _.filter(refValueArr, (v) => transArr.indexOf(v) >= 0);
              return r.length == transArr.length
            }
            else if (condition == 'notSelected') {
              let refValueArr = _.map(refValue, "value");
              let r = _.filter(refValueArr, (v) => transArr.indexOf(v) >= 0);
              return r.length != transArr.length
            }
          }
          if (refCtrl.controlMode == 'score') {
            const scoreItem = refValue.find(item => item.value == show.value);
            const score = scoreItem?.score;
            if (condition == 'selected') {
              if(show.startScore==='any'){
                return score!= null&&score!==''
              }else {
                return show.startScore <= score && score <= show.endScore;
              }
              
            }
            else if (condition == 'notSelected') {
              if(show.startScore==='any'){
                return score == null||score === ''
              }else {
                return show.startScore > score || score > show.endScore;
              }
             
            }
          }
          if (refCtrl.controlMode == 'proportion') {
            const it = refValue.find(item => item.value == show.value);
            const propor = it?.score;
            if (condition == 'selected') {
              if (show.symbol === '|') {
                return propor >= show.minValue && propor <= show.maxValue
              } else {
                return eval(`${propor}${show.symbol}${show.minValue}`)
              }
            }
          }
          if (condition == 'show') {
            return this.$controls[refCtrl.$designId].visible;
          }
          else if (condition == 'notShow') {
            return !this.$controls[refCtrl.$designId].visible;
          }
        }
      }
      return true;
    },
    async validate(vdatakey, index) {
      let dict = this.metadataDict;
      let allKeys = this.metadataKeyList;
      let keys = [];
      //全局验证
      if (arguments.length == 0) {
        keys = _.filter(allKeys, (it) => {
          if (!dict[it].inSubTable) {
            return true;
          }
        })
      }
      else {
        keys = (dsf.type(vdatakey) == 'array' ? vdatakey : [vdatakey]);
      }
      //获取所有没有被隐藏的题目的数据key
      let validFields = [];
      _.each(this.$postFields, (it) => {
        if (it.visible) {
          dsf.array.ensure(validFields, it.formName);
        }
      });
      keys = _.filter(keys, (it) => {
        return validFields.indexOf(it) >= 0;
      })
      let arrs = this.$getValidateFns(keys, dict, index);
      let errors = await this.$execValidateFns(arrs);
      return errors;
    },
    //调用表单保存api
    saveAPI(params) {
      return this.$webAPI.questionSave(params, true);
    },
    $getInitDataAPI(params) {
      return this.$webAPI.questionInit(params, true);
    },
    //获取表单数据API接口
    $getFormDataAPI(params) {
      return this.$webAPI.getQuestionData(params, true);
    },
    $getPostData(options = {}) {
      let _this = this;
      let data = dsf.mix(true, {}, this.$viewData);
      _.each(this.$postFields, (it) => {
        let prop = this.$controls[it.$designId];
        if (prop.logicIgnore) {
          data[it.$formName] = null;
        }
      })
      this.$filterInValidMetaDataValueAttrs(data);
      data.batch_id = this.$batch_id || ""
      return data;
    }
  },
  watch: {
    "queryString.batch_id": function (v) {
      this.$batch_id = v;
    }
  }

}
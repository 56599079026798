export default function (dsf, options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    asqType:"file",
    group:"",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    dataSourceWatch: true,
    defaultValue: null,
    isJSONString:true,
    dataSource: {
      code: null,
      type: "static",
      attach: [],
    },
    valueAttributes: [{
      name: "签名上传",
      code: "file",
      type: dsf.metadata.getDataType("object"),
      length: 500,
      defaultValue: null,
      unit: null,
      encrypt:false
    }]
  };
}
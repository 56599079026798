/*
 * @Descripttion: 矩阵题
 * @Author: zhanghang
 * @Date: 2022-03-08 13:51:03
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-28 13:56:17
 */
import questionControl from './asqQuestionControl';
import questionDefaultOptions from './asqQuestionDefaultOptions';
import { validator as _validator, MESSAGE_TYPE, types } from '_question/utils/validator'
const validator = {
  ..._validator,
  min: function (value, min) {
    return value&&value.length >= min
  }
}
export default {
  mixins: [questionControl, questionDefaultOptions],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get('question-matrix-item-meta-data')
        return md
      }
    },
    colShuffle:{
      type: Boolean,
      default: false
    },
    rowShuffle:{
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredMsg: {
      type: String,
      default: '该项必须填写'
    },
  },
  data() {
    return {
      validator,
      types,
      MESSAGE_TYPE,
      rowsData: [],
    }
  },
  computed: {
    // 列数据
    columns() {
      return (this.colShuffle&&!this.isDesign)?_.shuffle(this.items[0]):this.items[0]
    },
    $value: {
      get() {
        return this.value
      },
      set(value) {
        console.log('value',value)
        this.emitValueChange(value)
      }
    }
  },
  watch: {
    items(v) {
      if (this.isDesign) {
        this.initRowData()
      }
    },
    $value: {
      handler(value) {
        let rowsData = this.rowsData
        for (let key in value) {
          let rowId = key.split('-')[0]
          let columnId = key.split('-')[1]
          let row = rowsData.findIndex((it) => it.uuid === rowId)
          if (row > -1 && rowsData[row].model[columnId]) {
            rowsData[row].model[columnId].value = value[key].value
            rowsData[row].model[columnId].attach = value[key].attach
          }

          // 如果经过行列互换的
          let column = rowsData.findIndex((it) => it.uuid === columnId)
          if (column > -1 && rowsData[column].model[rowId]) {
            rowsData[column].model[rowId].value = value[key].value
            rowsData[column].model[rowId].attach = value[key].attach
          }
        }
      },
      deep: true
    },
    rowsData: {
      handler(v) {
        let valueObj = {}
        v.forEach((it) => {
          let key = it.uuid
          for (let uuid in it.model) {
            let _key = key + `-${uuid}`
            valueObj[_key] = {
              value: it.model[uuid].value,
              attach: it.model[uuid].attach
            }
          }
        })
        this.$value = valueObj
      },
      deep: true
    }
  },
  created() {

    this.initRowData()
  },
  methods: {
    //初始化行数据
    initRowData() {
      let row = []
      if (this.columns && this.items[1]) {
        let rowsNames = (this.rowShuffle&!this.isDesign)?_.shuffle(this.items[1]):this.items[1]
        //值对象
        let model = {}
        //设置项
        let setting = {}

        this.columns.forEach((it) => {
          model[it.uuid] = {
            value: '',
            attach: ''
          }
          setting[it.uuid] = it.setting
        })

        row = rowsNames.map((it, index) => {
          return {
            text: it.text,
            uuid: it.uuid,
            model: _.cloneDeep(model),
            setting: { ...setting }
          }
        })
      }
      //初始化value
      this.rowsData = row
    },
    //验证
    $formItemInnerValidate() {
      if (this.required) {
        let flag = false
        let $value = this.$value
        for (let key in $value) {
          if ($value[key].value) flag = true
        }
        if (!flag) {
          return {
            message: this.requiredMsg
          }
        }
      }
    },
  }
}